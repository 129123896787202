import React, { useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { auth } from '../../firebase/config';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import {Link as MuiLink, Box, Button, Container, Typography, TextField, CircularProgress, Grid, IconButton, Divider, Alert } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import Colors from '../../constants/colors';
import { LoadingButton } from '@mui/lab';
import MotionBox from '../../components/motion/motionBox';
import LayoutType from '../../constants/layout-type';

const LoginPage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [loadingFromEmailVerification, setLoadingFromEmailVerification] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const signInWithEmailPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoadingFromEmailVerification(true);
        setLoading(true);
        setErrorMessage(null); // Reset the error message at the start
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/app/dashboard/');
        } catch (error: any) {
            console.error("Error signing in with email/password", error);
            setErrorMessage("Failed to sign in. Please check your email and password.");
        } finally {
            setLoading(false);
            setLoadingFromEmailVerification(false);
        }
    };

    const handleOAuthSignIn = async (providerId: string) => {
        setLoading(true);
        setErrorMessage(null);
        let provider;
        switch (providerId) {
            case 'google.com':
                provider = new GoogleAuthProvider();
                break;
            case 'apple.com':
                provider = new OAuthProvider('apple.com');
                break;
            case 'microsoft.com':
                provider = new OAuthProvider('microsoft.com');
                break;
            default:
                console.error('Unsupported provider');
                setLoading(false);
                return;
        }

        try {
            await signInWithPopup(auth, provider);
            navigate('/app/dashboard/');
        } catch (error: any) {
            console.error(`Error signing in with ${providerId}: `, error);
            setErrorMessage("Failed to sign in. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const firstBoxAnimationProps = useMemo(() => ({
        initial: { opacity: 0, y: -50 },
        animate: { opacity: 1 , y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 1 },
        style: { visibility: 'visible', background: Colors.BACKGROUND_DARK }
    }), []);

    return (<>
        <MotionBox
            {...firstBoxAnimationProps}
        >
            <Container maxWidth="md">
                <Box textAlign="center" pt={13} pb={2} mb={0} flexDirection="column" flex="1" alignItems="center" alignContent="center">
                    <Typography component="h1" variant="h1" sx={{ mt: 4, mb: 1, maxWidth: "100%" }}>
                        Sign In
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                        Access your Screen Keep account
                    </Typography>
                </Box>
            </Container>
        </MotionBox>
        <Container component="main" maxWidth="sm">
            <Box sx={{ pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Box component="form" onSubmit={signInWithEmailPassword} noValidate sx={{ mt: 4, width: '100%', textAlign: 'center' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {errorMessage && (
                        <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>
                    )}
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="body2" sx={{ mt: 2, maxWidth: '75%', display: 'inline-block' }}>
                            By continuing with this service, you agree to our
                            <MuiLink to="/legal/terms-of-use/" component={Link} sx={{ ml: 0.5 }} underline="hover">
                                Terms of Service
                            </MuiLink> and
                            <MuiLink to="/legal/privacy-policy/" component={Link} sx={{ ml: 0.5 }} underline="hover">
                                Privacy Policy
                            </MuiLink>.
                        </Typography>
                    </Box>
                    {loading && !loadingFromEmailVerification ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            loading={loading}
                            sx={{ mt: 3, mb: 2, maxWidth: '75%' }}
                        >
                            Sign In
                        </LoadingButton>
                    )}
                    <Divider sx={{ m: 2 }}>OR</Divider>
                    <Box justifyContent="center">
                            <Button
                                variant="outlined"
                                startIcon={<GoogleIcon />}
                                onClick={() => handleOAuthSignIn('google.com')}
                                fullWidth
                                sx={{ mt: 3, mb: 2, maxWidth: '75%' }}
                            >
                                Continue With Google
                            </Button>
                    </Box>
                </Box>
                <MuiLink to="/auth/register" color="inherit" component={Link}>
                    <Typography variant="body2" sx={{ mt: 4 }}>
                        {"Don't have an account? Register"}
                    </Typography>
                </MuiLink>
                <MuiLink to="/auth/request-reset" color="inherit" component={Link} sx={{ pt: 2 }}>
                    <Typography variant="body2">
                        Forgot your password? Request a reset
                    </Typography>
                </MuiLink>
            </Box>
        </Container>
    </>);
};

export default LoginPage;

LoginPage.layoutType = LayoutType.DefaultFullWidth;

export const Head: React.FC = () => <title>ScreenKeep - Login</title>;